<template>
    <v-dialog v-model="dialog" width="1200">
        <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                Click Me
            </v-btn> -->
            <a v-bind="attrs" v-on="on">
                VER MÁS
            </a>
        </template>

        <v-card class="px-5 pt-5 pb-5">
            <v-card-title class="justify-left d-flex flex-row pb-2 align-start">
                <h2 class="text-uppercase">{{ news.title }}</h2>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col col="6" md="6">
                            <v-img :src="news.photo_url" :alt="news.title"></v-img>
                        </v-col>
                        <v-col col="6" md="6">
                            <p>{{ news.content }}</p>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col col="12" md="12" class="text-left font-weight-bold">
                            <p>Fuente: {{ news.source }}</p>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>


        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'NewsModal',
    props: ['news'],
    data: () => ({
        dialog: false
    })
}
</script>

<style>
</style>